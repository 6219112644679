import { Container } from "react-bootstrap";
import MailChimpSubscribe from "../components/MailChimpSubscribe";
import { Link } from "react-router-dom";


const Newsletter = () => {
    document.title = "Melissa Gerhold - Newsletter SignUp";

    return(
        <>
<Container fluid className="bg-dark text-white p-3">
        <Link
          to="/"
          className="btn btn-primary text-bg-dark"
          style={{ backgroundColor: "#F8D397", borderColor: "#F8D397" }}
          >
          Home
        </Link>
      </Container>
              <Container>
<MailChimpSubscribe />

</Container>
            </>
    );
};

export default Newsletter;